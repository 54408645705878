@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-color: white;
  --primary-text-color: black;
  --child-bg-color: #e6edfc;
  --child-border-color: #eaeaec;
  --icon-bg-color: #fafcfd;
  --table-bg-color: #f6f8fe;
  --primary-btn-text-color: #2197ed;
}

[data-theme="dark"] {
  --background-color: #121212;
  --primary-text-color: white;
  --child-bg-color: #000000;
  --child-border-color: #333333;
  --icon-bg-color: #1c1c1c;
  --table-bg-color: #252525;
  --primary-btn-text-color: white;
}

.children-container {
  background-color: var(--child-bg-color);
}
body {
  background-color: var(--background-color);
  color: var(--primary-text-color);
}

.placeholder-style::placeholder {
  color: #6c6c6c;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.menuitem svg path {
  transition: fill 0.3s ease;
}

.group:hover .menuitem svg path {
  fill: #6699ff;
}
/* Dark mode style for sidebar Svg icon*/
[data-theme="dark"] .group:hover .menuitem svg path {
  fill: white;
}

.menuitem.active svg path {
  fill: #6699ff;
}
.menuitem.darkActive svg path {
  fill: white;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.custom-pagination .swiper-pagination-bullet {
  background-color: white;
  width: 10px;
  height: 10px;
}

.custom-pagination .swiper-pagination-bullet-active {
  background-color: #6699ff;
  width: 12px;
  height: 12px;
}
.custom-pagination-1 .swiper-pagination-bullet {
  background-color: white;
  width: 10px;
  height: 10px;
}

.custom-pagination-1 .swiper-pagination-bullet-active {
  background-color: #6699ff;
  width: 12px;
  height: 12px;
}

.custom-pagination-2 .swiper-pagination-bullet {
  background-color: white;
  width: 10px;
  height: 10px;
}
.custom-pagination-2 .swiper-pagination-bullet-active {
  background-color: #6699ff;
  width: 12px;
  height: 12px;
}

.custom-pagination-3 .swiper-pagination-bullet {
  background-color: white;
  width: 10px;
  height: 10px;
}
.custom-pagination-3 .swiper-pagination-bullet-active {
  background-color: #6699ff;
  width: 12px;
  height: 12px;
}

.custom-pagination-4 .swiper-pagination-bullet {
  background-color: #89b0ff;
  width: 10px;
  height: 10px;
}
.custom-pagination-4 .swiper-pagination-bullet-active {
  background-color: #6699ff;
  width: 12px;
  height: 12px;
}

.custom-pagination-5 .swiper-pagination-bullet {
  background-color: #89b0ff;
  width: 10px;
  height: 10px;
}
.custom-pagination-5 .swiper-pagination-bullet-active {
  background-color: #6699ff;
  width: 12px;
  height: 12px;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  height: 56px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
}

.custom-textfield .MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.custom-textfield
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #6699ff !important;
}
.custom-textfield .MuiInputLabel-root.Mui-focused {
  color: #6699ff !important; /* Label color on focus */
}
